<template>
  <div class="dashboard">
    <Loader v-if="!shifts || shifts.length == 0" />
      <div class="dashboard__container pb-0">
        <div class="dashboard__container--header">
          <div class="flex align-center">
            <span>
            <h1>Shifts</h1>
          </span>
          </div>
        </div>
        <div class="dashboard__container--body">
        <vue-good-table
            :columns="columns"
            :rows="shifts"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 50,
            }"
          >
          
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'day'">
              <span v-if="props.row.day">{{props.row.day | moment("MM/DD/YYYY")}}</span>
            </span>

            <span v-else-if="props.column.field == 'startTime'">
              <span v-if="props.row.startTime">
                {{formatAMPM(props.row.startTime) | moment("h:mm:ss a") }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'endTime'">
              <span v-if="props.row.endTime">
                {{formatAMPM(props.row.endTime) | moment("h:mm:ss a") }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'poNumber'">
              <input type="text" style="background:#f5f5f5;"v-model.trim="props.row.poNumber" @blur="updateShift(props.row)" id="po" />
            </span>
            <span v-else-if="props.column.field == 'invoiceNumber'">
              <input type="text" style="background:#f5f5f5;"v-model.trim="props.row.invoiceNumber" @blur="updateShift(props.row)" id="invoice" />
            </span>
            <span v-else-if="props.column.field == 'paid'">
              <input type="checkbox" v-model="props.row.paid" @change="updateShift(props.row)">
            </span>
            <span v-else-if="props.column.field == 'factored'">
              <input type="checkbox" v-model="props.row.factored" @change="updateShift(props.row)">
            </span>

            <span v-else-if="props.column.field == 'event'">
              <router-link :to="`/orders/` + props.row.eventId">
                {{props.row.event}}
              </router-link>
              <i class="fa-solid fa-check" v-if="props.row.visible" style="color:green;"></i>
            </span>

            <span v-else-if="props.column.field == 'client'">
              <span v-if="props.row.eventInfo && props.row.eventInfo.client && props.row.eventInfo.client[0] && props.row.eventInfo.client[0].title">{{props.row.eventInfo.client[0].title}}</span>
            </span>
            
            <span v-else-if="props.column.field == 'visible'">
              <i class="fa-solid fa-check" v-if="props.row.visible" style="color:green;"></i>
            </span>
            <span v-else-if="props.column.field == 'client'">
              <span v-if="props.row.venue.client && props.row.venue.client[0]">
                {{props.row.venue.client[0].title}}
              </span>
            </span>
            <span v-else-if="props.column.field == 'groups'">
              <span v-if="props.row.groups && props.row.groups.length >= 1">
                <button class="chipDark mb-1" v-for="(item, index) in props.row.groups" :key="index">{{item.title}}</button>
              </span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import * as moment from 'moment'
const fb = require('../firebaseConfig.js')

export default {
  name: 'shiftsTable',
  data: () => ({
    performingRequest: false,
    columns: [
      {
        label: 'Date',
        field: 'day',
        width: '100px'
      },
      {
        label: 'Status',
        field: 'staffingStatus',
        width: '80px'
      },
      {
        label: 'Invoice',
        field: 'invoiceNumber',
        width: '120px'
      },
      {
        label: 'PO',
        field: 'poNumber',
        width: '120px'
      },
      {
        label: 'Paid',
        field: 'paid',
        width: '60px'
      },
      {
        label: 'Factored',
        field: 'factored',
        width: '60px'
      },
      {
        label: 'Client',
        field: 'client',
        width: '120px'
      },
      {
        label: 'Order',
        field: 'event',
        width: '120px'
      },
      {
        label: 'Location',
        field: 'location',
         width: '120px'
      },
      {
        label: 'Venue',
        field: 'eventInfo.venue.title',
        width:'180px'
      },
      {
        label: 'Code',
        field: 'eventInfo.venue.shortCode',
        width:'60px'
      },
      {
        label: 'Staff',
        field: 'staff',
         width: '60px'
      },
      
      {
        label: 'Start Time',
        field: 'startTime',
        width: '120px'
      },
      {
        label: 'End Time',
        field: 'endTime',
        width: '120px'
      },
      {
        label: 'Name',
        field: 'position.title',
        width: '120px'
      },
      {
        label: 'workerType',
        field: 'workerType',
        width: '120px'
      },
    ],
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'shifts']),
  },
  created () {
    this.$store.dispatch("getShifts");
  },
  components: {
    Loader,
  },
  methods: {
    updateShift(row) {
      fb.shiftsCollection.doc(row.id).update(row)
    },
    onRowClick(params) {
      let url = `/orders/` + params.row.eventId + '/placement'
      console.log(url)
      router.push(url)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
      } else {
        return null
      }
    },
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearShiftsState')
  }
}

</script>